<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Specialization Information {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab
            :title="`Specialization Information ${isEdit ? 'Edit' : 'Add'}`"
          >
            <div class="p-2">
              <ValidationObserver
                ref="specializationForm"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="specializationForm"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_speciality"
                          class="col-lg-12 col-md-12 required"
                          >Speciality</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="speciality_id"
                              :value="profile.speciality_id"
                              @change="handleChangeSelect"
                              :options="
                                options && isNurse
                                  ? options['nurseSpecialitySubSpeciality']
                                  : options['speciality_id']
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <!-- <label
                          name="lbl_nurse_yrs_exp"
                          class="col-lg-12 col-md-12 required"
                          >Number of Years Experience({{
                            profile.experience_in_years || 0
                          }}
                          yrs)</label
                        > -->
                        <label
                          name="lbl_nurse_yrs_exp"
                          class="col-lg-12 col-md-12 required"
                          >Years of Experience in this Speciality
                        </label>
                        <div
                          class="
                            col-lg-12 col-md-12 col-sm-12
                            experience_in_years
                          "
                        >
                          <v-slider
                            name="experience_in_years"
                            :min="0"
                            :max="20"
                            :value="profile.experience_in_years"
                            v-model="profile.experience_in_years"
                            @change="handleSliderYrsExp"
                          ></v-slider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="
                      profile.speciality_id
                        ? profile.speciality_id.label == 'Other - not listed'
                          ? true
                          : false
                        : false
                    "
                  >
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_other_speciality"
                          class="col-lg-12 col-md-12"
                          >Name Speciality</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="other_speciality"
                            :value="profile.other_speciality"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow v-if="isAHP && options['sub_speciality_id'].length">
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label class="col-lg-12 col-md-12"
                          >Sub Type / Licensed as</label
                        >
                        <div class="col-lg-12 col-md-12">
                          <Select
                            name="sub_speciality_id"
                            @input="handleChangeSelect"
                            :value="profile.sub_speciality_id"
                            :options="
                              options && options['sub_speciality_id']
                                ? options['sub_speciality_id']
                                : []
                            "
                            option_label="label"
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow class="mb-3">
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_is_main" class="col-lg-12 col-md-12"
                          >Is this your main Speciality?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="main"
                            :value="profile.main"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                            :disabled="profile.is_main"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label name="lbl_is_current" class="col-lg-12 col-md-12"
                          >Is this your current job?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="is_current"
                            :value="profile.is_current"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow v-if="profile.is_current == false">
                        <label class="col-lg-12 col-md-12">
                          Date of Last Practice
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            name="dob"
                            rules="date_validate|no_future_date"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="last_practiced"
                              :value="profile.last_practiced"
                              @input="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import m from "moment";
extend("required", { ...required, message: "This field is required" });
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
const debounce = require("lodash.debounce");
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";
import CVPreview from "./CVPreview";
import TextInput from "@/components/reusable/Fields/TextInput";
export default {
  props: {
    specializationInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    nurseSpeciality: {
      type: Array,
      default: [],
    },
  },
  components: {
    Select,
    DateInput,
    RadioButton,
    CVPreview,
    TextInput,
  },
  data() {
    return {
      profile: {},
      payload: {},
      errors: [],
      activeTab: 1,
    };
  },
  computed: {
    ...mapGetters([
      "specialityByTypeAndCountry",
      "isFetchingFilter",
      "subSpecialityFilterBySpeciality",
      "getNurseSubSpeciality",
    ]),
    options() {
      return {
        speciality_id:
          this.specialityByTypeAndCountry({
            candidateType: this.candidateInfo?.candidate_type_id,
            country_id:
              this.candidateInfo.highest_qualification_country_id || null,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile?.speciality_id?.code,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        nurseSpecialitySubSpeciality: this.getNurseSubSpeciality,
      };
    },
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        if (this.isEdit && isEmptyObjectCheck(this.profile)) {
          let data = this.specializationInfo;
          this.profile = {
            speciality_id: data?.speciality
              ? {
                  code: data?.speciality?.speciality_id,
                  label: data?.speciality?.speciality,
                }
              : null,
            experience_in_years: data?.experience_in_years,
            is_current: data?.is_current,
            main: data?.main,
            is_main: data?.main,
            last_practiced: data?.last_practiced,
            other_speciality: data?.other_speciality,
          };
          if (this.isAHP) {
            this.profile = {
              ...this.profile,
              sub_speciality_id: data?.sub_speciality_id
                ? {
                    code: data?.sub_speciality?.sub_speciality_id,
                    label: data?.sub_speciality?.sub_speciality,
                  }
                : null,
            };
          }
          if (this.isNurse) {
            this.profile.speciality_id = this.getNurseSpecialityFromIds(data);
            this.profile.sub_speciality_id =
              data?.sub_speciality_id !== null
                ? {
                    code: data?.sub_speciality?.sub_speciality_id,
                    label: data?.sub_speciality?.sub_speciality,
                  }
                : null;
          }
        }

        return true;
      }
      return false;
    },
    isAHP() {
      return this.candidateInfo?.candidate_type_id === 3 || false;
    },
    isNurse() {
      return this.candidateInfo?.candidate_type_id === 2 || false;
    },
  },
  methods: {
    ...mapActions(["initFetchSpecializationOptions", "showToast"]),
    getNurseSpecialityFromIds(data) {
      const { speciality_id, sub_speciality_id } = data;
      if (speciality_id && sub_speciality_id) {
        const res = this.getNurseSubSpeciality.filter(
          (v) =>
            v.code === speciality_id &&
            v.sub_speciality_id === sub_speciality_id
        );
        return res && res.length ? res[0] : {};
      } else {
        return {
          code: data?.speciality?.speciality_id,
          label: data?.speciality?.speciality,
        };
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.specializationForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      } else if (!this.profile?.experience_in_years) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill Number of years Experience!",
        });
        return;
      }
      let hasError = { hasError: false, errorMsg: "" };
      if (
        this.specializationInfo?.candidate_speciality_id === null &&
        this.isEdit === false
      ) {
        hasError.hasError = this.nurseSpeciality.some(
          (e) =>
            e?.speciality_id === this.profile?.speciality_id?.code &&
            (this.isNurse && e.sub_speciality_id
              ? e.sub_speciality_id === this.profile?.sub_speciality_id?.code
              : true)
        );
        hasError.errorMsg = hasError.hasError
          ? "Speciality Already Exists!"
          : "";
        if (!hasError.hasError && this.nurseSpeciality.length === 4) {
          hasError = {
            hasError: true,
            errorMsg: "Maximum No of speciality 4",
          };
        }
      } else {
        hasError.hasError = this.nurseSpeciality.some(
          (e) =>
            e?.speciality_id === this.profile?.speciality_id?.code &&
            this.specializationInfo?.candidate_speciality_id !==
              e.candidate_speciality_id &&
            (this.isNurse && e.sub_speciality_id
              ? e.sub_speciality_id === this.profile?.sub_speciality_id?.code
              : true)
        );
        hasError.errorMsg = hasError.hasError
          ? "Speciality Already Exists!"
          : "";
      }
      if (!hasError.hasError) {
        let finalPayload = {
          speciality_id: this.profile?.speciality_id?.code,
          experience_in_years: this.profile?.experience_in_years,
          last_practiced: this.profile?.last_practiced,
          is_current: this.profile?.is_current,
          main: this.profile?.main,
          is_special_interest_update: false,
          other_speciality:
            this.profile?.speciality_id?.label === "Other - not listed"
              ? this.profile?.other_speciality
              : null,
        };
        if (this.isEdit)
          finalPayload = {
            ...finalPayload,
            candidate_speciality_id:
              this.specializationInfo?.candidate_speciality_id,
          };
        if (this.isAHP || this.isNurse) {
          finalPayload = {
            ...finalPayload,
            sub_speciality_id: this.profile?.sub_speciality_id?.code || null,
          };
        }
        this.modalCallBack(true, finalPayload);
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: hasError.errorMsg,
        });
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "speciality_id") {
        let v = null;
        if (this.isNurse)
          v = { code: value?.sub_speciality_id, label: value?.sub_speciality };
        this.handleChangeSelect("sub_speciality_id", v);
      }
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.profile, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.profile, name, m(value).format("YYYY-MM-DD"));
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM-DD") : null);
    },
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    handleSliderYrsExp: debounce(function (value) {
      Vue.set(this.profile, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
    }, 500),
  },
  mounted() {
    this.initFetchSpecializationOptions();
  },
};
</script>